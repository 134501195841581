<template>
  <t-modal
    name="RefundRequestPopup"
    :variant="`transaction`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="popup"
  >
    <section class="px-8 pt-10">
      <div class="font-medium text-28px text-oCharcoal">
        Refund
      </div>

      <div class="p-8">
        <label class="mt-8 text-sm font-bold text-gray-800">
          Refundable Amount ({{ currencySymbol }})
          <input
            type="number"
            class="w-full h-16 px-3 text-2xl font-bold text-center text-orange-400 border rounded focus:outline-none"
            v-model.number="refundAmount"
            :max="refundAmount"
            :min="0"
            step="0.01"
            ref="refundAmountInput"
          />
        </label>

        <AppInput
          class="my-3"
          v-model="requestedRefundAmount"
          :label="`Requested Amount(${currencySymbol})`"
          type="text"
          :name="`Requested Amount(${currencySymbol})`"
          :isInfoEnabled="false"
          disabled
        />

        <AppInput
          class="my-3"
          v-model="rider"
          label="Rider"
          type="text"
          name="Rider"
          :isInfoEnabled="false"
          disabled
        />

        <div class="flex items-center justify-end mt-12 mb-4 space-x-4">
          <AppButton
            :variant="`secondary`"
            :text="`Cancel`"
            @click="$refs.popup.hide()"
          />

          <AppButton
            variant="primary"
            text="Accept"
            :isLoading="isLoading"
            @click="submitForm"
          />
        </div>
      </div>
    </section>
  </t-modal>
</template>

<script>
import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'
import { RefundRequestConfig } from '@/config/RefundRequestConfig'
export default {
  name: 'RefundRequestPopup',
  data: () => ({
    popName: 'RefundRequestPopup',
    isLoading: false,
    refundAmount: 0,
    requestedRefundAmount: 0,
    rider: '--',
    currencySymbol: '--',
    rawData: null,
  }),
  async mounted() {
    EventBus.$on(
      RefundRequestConfig.events.refundRequestPopup,
      async (data) => {
        this.rawData = data
        this.refundAmount = data.refundable_amount
        this.requestedRefundAmount = data.requested_amount
        this.rider = `${data.rider.full_name}(${data.rider.phone_number})`
        this.currencySymbol = data.currency.symbol
        setTimeout(() => {
          if (this.$refs.refundAmountInput) {
            this.$refs.refundAmountInput.focus()
          }
        }, 500)
      }
    )
  },

  methods: {
    submitForm() {
      if (this.refundAmount > this.rawData.refundable_amount) {
        this.$notify({
          group: 'generic',
          type: 'warn',
          title: `Warning!`,
          text: `Refund amount could not higher than ${this.rawData.currency.symbol}${this.rawData.refundable_amount}`,
        })
        return
      }

      const formData = new FormData()
      formData.append('status', 1)
      formData.append('amount', this.refundAmount)

      const endpoint = useEndpoints.refundApplication.update(this.rawData.id)

      this.isLoading = true
      this.$http
        .patch(endpoint, formData)
        .then(() => {
          this.refundAmount = 0
          this.$emit('refresh')
          this.$refs.popup.hide()

          this.$notify({
            group: 'generic',
            type: 'warn',
            title: 'Success',
            text: 'Refund has been successful',
          })
        })
        .catch((err) => {
          console.log('refund-request-err', { err })
          this.$notify({
            group: 'generic',
            type: 'warn',
            title: 'Failed',
            text: 'Failed to refund',
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
